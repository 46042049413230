import React, { Component } from 'react'
import {
    View,
    Image,
    Pressable,
    FlatList,
    StyleProp,
    ImageStyle,
    ViewStyle,
    PressableProps,
} from 'react-native'

import styles from './styles'
import STRINGS from '../../utils/strings'
import { Typography, TypographyProps } from '../Typography/Typography'

export interface PaginationProps {
    siblingCount?: number
    boundaryCount?: number
    pageCount?: number
    initialPage?: number
    totalItems?: number
    onPageChange?: (page: number) => void
    containerStyle?: StyleProp<ViewStyle>
    subContainerStyle?: StyleProp<ViewStyle>
    paginationItemStyle?: StyleProp<PressableProps>
    textProps?: Omit<TypographyProps, 'children'>
    activeTextProps?: Omit<TypographyProps, 'children'>
    ellipsisProps?: Omit<TypographyProps, 'children'> & { children?: string }
    iconStyle?: StyleProp<ImageStyle>
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
    showIcon?: boolean
    showDetailedPageResults?: boolean
    totalItemProps?: Omit<TypographyProps, 'children'>
    testID?: string
    customEllipsis?: React.ReactNode
}

interface PaginationState {
    selectedPage: number | null
    pageNumbers: PageNumber[]
}

type PageNumber = number | '...'

export class Pagination extends Component<PaginationProps, PaginationState> {
    constructor(props: PaginationProps) {
        super(props)
        this.state = {
            selectedPage: props.initialPage || 1,
            pageNumbers: [],
        }
    }

    componentDidMount() {
        const { initialPage } = this.props
        this.setState({
            pageNumbers: this.getPageNumbers(),
            selectedPage: initialPage || 1,
        })
    }

    componentDidUpdate(prevProps: PaginationProps, prevState: PaginationState) {
        if (prevState?.selectedPage !== this.state?.selectedPage) {
            this.setState({
                pageNumbers: this.getPageNumbers(),
            })

            this.props.onPageChange?.(this.state.selectedPage)
        }
    }

    handlePageChange = (page: number) => {
        this.setState({ selectedPage: page })
    }

    renderPageButton = ({ item }: { item: PageNumber }) => {
        const { selectedPage } = this.state
        const isActive = selectedPage === item
        const isEllipsis = item === '...' // Check if the item is ellipsis

        const {
            activeTextProps = {},
            textProps = {},
            ellipsisProps = {},
            customEllipsis,
        } = this.props

        let style

        if (isActive) {
            style = { ...styles.activePageText, ...activeTextProps?.style }
        } else if (isEllipsis) {
            style = { ...styles.ellipsisText, ...ellipsisProps?.style }
        } else {
            style = { ...styles.pageText, ...textProps?.style }
        }

        const _item = ellipsisProps?.children
            ? item.toString().replace('...', ellipsisProps.children)
            : item

        return (
            <Pressable
                testID={STRINGS.PAGE_BTN_TESTID}
                style={[styles.pageButton, this.props.paginationItemStyle]}
                onPress={() => !isEllipsis && this.handlePageChange(item)}
                disabled={isEllipsis}
            >
                {isEllipsis && customEllipsis ? (
                    customEllipsis
                ) : (
                    <Typography style={style}>{_item}</Typography>
                )}
            </Pressable>
        )
    }
    goToPrevPage = () => {
        const newPage = Math.max(this.state.selectedPage - 1, 1)

        this.setState({ selectedPage: newPage })
    }

    goToNextPage = () => {
        const newPage = Math.min(
            this.state.selectedPage + 1,
            this.props.pageCount,
        )

        this.setState({ selectedPage: newPage })
    }

    getPageNumbers = () => {
        const {
            pageCount,
            siblingCount = 0,
            boundaryCount = pageCount,
        } = this.props
        const { selectedPage = 1 } = this.state
        const pageNumbers: any = []

        const boundaryStartEnd = Math.max(1, boundaryCount)
        const siblingStartStart = Math.max(1, selectedPage - siblingCount)
        const siblingEndEnd = Math.min(pageCount, selectedPage + siblingCount)
        const boundaryEndStart = Math.max(1, pageCount - boundaryCount + 1)

        const ellipsis = '...'

        for (let i = 1; i <= pageCount; i++) {
            if (i < selectedPage) {
                if (i <= boundaryStartEnd) {
                    pageNumbers.push(i)
                } else if (i === siblingStartStart - 1) {
                    pageNumbers.push(ellipsis)
                } else if (
                    i >= siblingStartStart &&
                    i <= siblingStartStart + siblingCount
                ) {
                    pageNumbers.push(i)
                }
            } else if (i > selectedPage) {
                if (i <= siblingEndEnd) {
                    pageNumbers.push(i)
                } else if (i === boundaryEndStart - 1) {
                    if (pageNumbers[i] !== ellipsis) pageNumbers.push(ellipsis)
                } else if (i >= boundaryEndStart) {
                    pageNumbers.push(i)
                }
            } else {
                pageNumbers.push(i)
            }
        }

        return pageNumbers as PageNumber[]
    }

    getPageRangeText = (): string => {
        const { pageCount, totalItems } = this.props
        const { selectedPage } = this.state

        const itemCountPerPage = Math.floor(totalItems / pageCount)
        const itemCountOnLastPage = totalItems % pageCount || itemCountPerPage

        if (!itemCountPerPage) return `0 - 0 of ${totalItems} results`

        const startItemCount = (selectedPage - 1) * itemCountPerPage + 1
        const endItemCount =
            selectedPage === pageCount
                ? startItemCount + itemCountOnLastPage - 1
                : startItemCount + itemCountPerPage - 1

        if (pageCount && pageCount > 0) {
            return `${startItemCount} - ${endItemCount} of ${totalItems} results`
        }
    }

    render() {
        const {
            containerStyle,
            subContainerStyle,
            iconLeft,
            iconRight,
            iconStyle,
            showIcon,
            showDetailedPageResults,
            totalItemProps,
            testID,
        } = this.props

        return (
            <View style={[styles.container, containerStyle]} testID={testID}>
                <View style={[styles.subContainer, subContainerStyle]}>
                    {showIcon && (
                        <Pressable
                            testID={STRINGS.LEFT_BTN_TESTID}
                            onPress={this.goToPrevPage}
                            disabled={this.state.selectedPage === 1}
                        >
                            {iconLeft ? (
                                iconLeft
                            ) : (
                                <Image
                                    resizeMode={'contain'}
                                    style={[styles.icon, iconStyle]}
                                    source={{
                                        uri: 'https://i.imgur.com/wS6e62V.png',
                                    }}
                                />
                            )}
                        </Pressable>
                    )}
                    <View>
                        <FlatList
                            data={this.state.pageNumbers}
                            renderItem={this.renderPageButton}
                            // Dont make item the key, there might be two '...' within the list
                            keyExtractor={(item, idx) => idx.toString()}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                    {showIcon && (
                        <Pressable
                            testID={STRINGS.RIGHT_BTN_TESTID}
                            onPress={this.goToNextPage}
                            disabled={
                                this.state.selectedPage ===
                                (this.props.pageCount || 1)
                            }
                        >
                            {iconRight ? (
                                iconRight
                            ) : (
                                <Image
                                    resizeMode={'contain'}
                                    style={[styles.icon, iconStyle]}
                                    source={{
                                        uri: 'https://i.imgur.com/cK6JBiC.png',
                                    }}
                                />
                            )}
                        </Pressable>
                    )}
                </View>
                {showDetailedPageResults && this.props.totalItems && (
                    <View style={styles.paginationDataContainer}>
                        <Typography
                            style={{
                                ...styles.totalTextView,
                                ...totalItemProps?.style,
                            }}
                            {...totalItemProps}
                        >
                            {this.getPageRangeText()}
                        </Typography>
                    </View>
                )}
            </View>
        )
    }
}
