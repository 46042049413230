import React from 'react'
import {
    ImageBackground,
    Pressable,
    Text,
    View,
    StyleSheet,
} from 'react-native'
import LinearGradientComponent from '../LinearGradient'
import COLORS from '../../utils/colors'
import { BigCardProps } from '../Card/interface'

export interface BigProductCardProps extends BigCardProps {}

export const BigProductCard: React.FC<BigProductCardProps> = ({
    containerStyle,
    image,
    gradientColors,
    label,
    pressableProps,
    title,
    onPressText,
}) => {
    return (
        <View style={[styles.bigCardContainerStyle, containerStyle]}>
            {/* @ts-ignore */}
            <ImageBackground
                resizeMode="cover"
                source={{ uri: String(image) }}
                style={[styles.bigCardImageBackgroundStyle]}
            >
                <LinearGradientComponent
                    colors={gradientColors}
                    style={{
                        ...styles.gradientOverlay,
                    }}
                >
                    <View style={[styles.bigCardContentContainerStyle]}>
                        <Text style={[styles.bigCardLabelStyle]}>{label}</Text>

                        <Text style={[styles.bigCardTitleStyle]}>{title}</Text>

                        <Pressable
                            {...pressableProps}
                            style={[styles.bigCardOnPressStyle]}
                        >
                            <View>
                                <Text style={styles.bigCardOnPressTextStyle}>
                                    {onPressText}
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                </LinearGradientComponent>
            </ImageBackground>
        </View>
    )
}

const styles = StyleSheet.create({
    bigCardContainerStyle: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    bigCardImageBackgroundStyle: {
        flex: 1,
    },
    bigCardTitleStyle: {
        fontSize: 30,
        color: 'white',
        fontWeight: 'bold',
        paddingTop: 4,
        flexShrink: 1,
    },
    bigCardLabelStyle: {
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
    },
    bigCardContentContainerStyle: {
        justifyContent: 'flex-end',
        flex: 1,
        padding: 16,
    },
    bigCardOnPressStyle: {
        backgroundColor: 'white',
        alignSelf: 'flex-start',
        marginTop: 24,
        paddingVertical: 10,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    bigCardOnPressTextStyle: {
        color: COLORS.COLOR_MEDIUMBLUE,
        fontWeight: 'bold',
    },
    gradientOverlay: {
        height: '100%',
    },
})
