import React from 'react'
import { View, StyleSheet, PressableProps, Pressable, Text } from 'react-native'

import COLORS from '../../../src/utils/colors'
import { Size } from 'src/utils/constants'
import { CardBaseProps, CardTitleProps } from '../Card/interface'

export interface EventCardProps extends CardBaseProps, CardTitleProps {
    participant?: string
    pressableProps?: PressableProps
    size?: Size
}

export const EventCardLoading: React.FC<EventCardProps> = ({ size }) => {
    const isMaxSize = size === 'max' ? true : false

    const containerStyle = isMaxSize
        ? {
              ...eventCardStyles.eventCardMaxStyle,
              paddingHorizontal: 20,
          }
        : { ...eventCardStyles.eventCardMinStyle, paddingHorizontal: 10 }

    return (
        <View
            testID="event-card-loading-container"
            style={[eventCardStyles.eventCardLoadingContainer, containerStyle]}
        >
            <View
                testID="event-title-loading"
                style={{
                    ...eventCardStyles.eventTitleLoading,
                    width: isMaxSize ? '20%' : '100%',
                }}
            />

            {isMaxSize && (
                <View
                    testID="event-participant-loading"
                    style={eventCardStyles.eventTitleLoading}
                />
            )}
        </View>
    )
}

export const EventCard: React.FC<EventCardProps> = ({
    size,
    testID,
    pressableProps,
    title,
    participant,
    loading,
    containerStyle,
}) => {
    const isMaxSize = size === 'max' ? true : false

    const sizeStyle = isMaxSize
        ? { ...eventCardStyles.eventCardMaxStyle, paddingHorizontal: 20 }
        : { ...eventCardStyles.eventCardMinStyle, paddingHorizontal: 10 }

    if (loading) {
        return <EventCardLoading size={size} />
    }

    return (
        <Pressable {...pressableProps} testID={`${testID}-event-card`}>
            <View
                style={[
                    eventCardStyles.eventCardContainer,
                    sizeStyle,
                    containerStyle,
                ]}
            >
                <Text style={eventCardStyles.eventNameStyle}>{title}</Text>

                {isMaxSize && (
                    <Text style={eventCardStyles.eventParticipantStyle}>
                        {participant}
                    </Text>
                )}
            </View>
        </Pressable>
    )
}

const eventCardStyles = StyleSheet.create({
    eventCardContainer: {
        backgroundColor: COLORS.COLOR_LIGHT_PURPLE,
        borderRadius: 6,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
        borderLeftWidth: 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    eventCardMaxStyle: {
        width: 718,
        height: 41,
    },
    eventCardMinStyle: {
        width: 101,
        height: 41,
    },
    eventNameStyle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    eventParticipantStyle: {
        fontSize: 12,
    },
    // Loading
    eventCardLoadingContainer: {
        paddingHorizontal: 20,
        backgroundColor: COLORS.COLOR_LIGHT_PURPLE,
        borderRadius: 6,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
        borderLeftWidth: 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    eventTitleLoading: {
        width: '20%',
        height: 10,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
    },
})
